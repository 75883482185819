import {
  Actor,
  Arena,
  GameMode,
  PlayerGameState,
  Puck,
  RandFunc,
  TeamId,
  TickParams,
} from './types';
import { getGameMode, moveActor, slow } from './utils';

export function createPuck(rand: RandFunc): Puck {
  return {
    id: 'puck',
    sort: 1000,
    type: 'puck',
    x: 0,
    y: 0,
    vx: 0,
    vy: 0,
    goal: false,
  };
}

export function tickPuck(tickParams: TickParams): Actor {
  const { actor, newState: gameState, context } = tickParams;
  const newPuck = actor as Puck;
  newPuck.message = '';

  if (newPuck.focus?.id && newPuck.focus?.id !== newPuck.lastTouchedBy) {
    newPuck.lastTouchedBy = newPuck.focus.id;
  }

  // slide the puck
  moveActor(newPuck, 0.1, context.arena);

  // and slow it down
  newPuck.vx = slow(newPuck.vx);
  newPuck.vy = slow(newPuck.vy);

  // is the puck in a net?
  if (Math.sign(newPuck.vx) === Math.sign(newPuck.x)) {
    const absY = Math.abs(newPuck.y);
    if (absY > 0 && absY < 30) {
      const absX = Math.abs(newPuck.x) - context.arena.goalLine;
      if (absX > 0 && absX < 33) {
        // goal!
        newPuck.goal = true;
        gameState.mode = getGameMode(GameMode.majorEvent, gameState.mode);
        // who last touched the puck?
        const lastTouchPlayer = gameState.actors.find(
          (it) => it.id === newPuck.lastTouchedBy
        ) as PlayerGameState;
        if (lastTouchPlayer) {
          newPuck.message = `GOAL!!!! ${lastTouchPlayer.info.name} Scored!!!!`;
          lastTouchPlayer.gameStats.goals += 1;
          if (newPuck.x < 0) {
            gameState.teamAScore++;
          } else {
            gameState.teamBScore++;
          }
        }
      }
    }
  }

  return newPuck;
}

export function findPuckZone(puck: Actor, arena: Arena): TeamId | null {
  if (Math.abs(puck.x) > arena.blueLine) {
    return puck.x > 0 ? 'A' : 'B';
  }
  return null;
}
