import { useState } from 'react';
import { PlayerInfo } from 'state/entities/PlayerInfo';
import { AllPlayerPositions, PlayerPosition } from 'state/types';
import { Button } from './button';

type UpdatePlayerFunc = (p: Partial<PlayerInfo>) => void;

interface PlayerControlProps {
  field: keyof PlayerInfo;
  label: string;
  updatePlayer: UpdatePlayerFunc;
}

interface PlayerRangeSliderProps extends PlayerControlProps {
  value: number;
  min?: number;
  max?: number;
  step?: number;
}

interface PlayerTextControlProps extends PlayerControlProps {
  value: string;
}

function PlayerTextControl(props: PlayerTextControlProps) {
  const { field, label, value, updatePlayer } = props;
  return (
    <>
      <td>
        <label htmlFor={field}>{label}</label>
      </td>
      <td>
        <input
          id={field}
          title={label}
          value={value}
          onChange={(e) => updatePlayer({ [field]: e.target.value })}
        />
      </td>
    </>
  );
}

function PlayerRangeSlider(props: PlayerRangeSliderProps) {
  const { field, label, value, min, max, step, updatePlayer } = props;
  return (
    <>
      <td>
        <label htmlFor={field}>{label}</label>
      </td>
      <td>
        <input
          id={field}
          title={`${label}: ${value}`}
          type="range"
          min={min}
          max={max}
          step={step}
          value={value}
          onChange={(e) => {
            updatePlayer({ [field]: e.target.valueAsNumber });
          }}
        />
        <span>{value}</span>
      </td>
    </>
  );
}

export interface PlayerEditorProps {
  player: PlayerInfo;
  onPlayerChanged: (newInfo: PlayerInfo, oldInfo: PlayerInfo | null) => void;
  onDelete: (infoToDelete: PlayerInfo) => void;
}

export function PlayerEditor(props: PlayerEditorProps) {
  const { player, onPlayerChanged, onDelete } = props;
  const [tempPlayer, setTempPlayer] = useState(player);

  function updatePlayer(newInfo: Partial<PlayerInfo>) {
    const oldState = tempPlayer;
    const newState = { ...tempPlayer, ...newInfo };
    setTempPlayer(newState);
    onPlayerChanged(newState, oldState);
  }

  function localDelete() {
    console.log('Delete ' + tempPlayer.id);
    onDelete(tempPlayer);
  }

  async function pickFace() {
    try {
      const [fileHandle] = await window.showOpenFilePicker({
        multiple: false,
        types: [
          {
            description: 'JPEG',
            accept: { 'application/jpeg': ['.jpg', '.jpeg'] },
          },
        ],
      });
      if (fileHandle) {
        const reader = new FileReader();
        reader.onloadend = (e) => {
          if (typeof reader.result === 'string') {
            const img = new Image();
            img.onload = () => {
              // scale it
              const canvas = document.createElement('canvas');
              canvas.width = canvas.height = 32;
              const ctx = canvas.getContext('2d');
              if (ctx) {
                ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, 32, 32);
              }

              updatePlayer({ faceDataURL: canvas.toDataURL() });
            };
            img.src = reader.result;
          }
        };
        const file = await fileHandle.getFile();
        reader.readAsDataURL(file);
      }
    } catch (error) {
      console.log(error);
    }
  }

  // should probably use grid...
  return (
    <fieldset>
      <legend>{tempPlayer.name}</legend>
      <table>
        <tbody>
          <tr>
            <td rowSpan={10}>
              <img
                src={tempPlayer.faceDataURL}
                height={32}
                width={32}
                alt={`${tempPlayer.name}'s face`}
                onClick={pickFace}
              />
            </td>
            <PlayerTextControl
              field="id"
              label="Id"
              value={tempPlayer.id}
              updatePlayer={updatePlayer}
            />
            <PlayerTextControl
              field="name"
              label="Name"
              value={tempPlayer.name}
              updatePlayer={updatePlayer}
            />
            <td>
              <label htmlFor="jersey">Jersey:</label>
              <input
                id="jersey"
                title="Jersey"
                type="number"
                min={1}
                step={1}
                value={tempPlayer.jersey}
                onChange={(e) =>
                  updatePlayer({ jersey: e.target.valueAsNumber })
                }
              />
            </td>
            <td>
              <label htmlFor="pos">Position:</label>
              <select
                id="pos"
                title="Position"
                value={player.position}
                onChange={(e) =>
                  updatePlayer({ position: e.target.value as PlayerPosition })
                }
              >
                {AllPlayerPositions.map((pos) => (
                  <option key={pos} id={pos} value={pos}>
                    {pos}
                  </option>
                ))}
              </select>
            </td>
            <td>
              <label htmlFor="line">Line:</label>
              <input
                id="line"
                title="Line"
                type="number"
                min={1}
                value={tempPlayer.line}
                onChange={(e) => {
                  updatePlayer({ line: e.target.valueAsNumber });
                }}
              />
            </td>
            <td>
              <Button title={`Delete ${tempPlayer.name}`} onClick={localDelete}>
                x
              </Button>
            </td>
          </tr>
          <tr>
            <PlayerRangeSlider
              field="reach"
              label="Reach"
              min={10}
              max={80}
              updatePlayer={updatePlayer}
              value={tempPlayer.reach}
            />
            <PlayerRangeSlider
              field="skateSpeed"
              label="Acceleration"
              min={1}
              max={10}
              step={1}
              updatePlayer={updatePlayer}
              value={tempPlayer.skateSpeed}
            />
          </tr>
          <tr>
            <PlayerRangeSlider
              field="shootPct"
              label="Shooting"
              value={tempPlayer.shootPct}
              updatePlayer={updatePlayer}
            />
            <PlayerRangeSlider
              field="passPct"
              label="Passing"
              value={tempPlayer.passPct}
              updatePlayer={updatePlayer}
            />
            <PlayerRangeSlider
              field="takePct"
              label="Puck stealing"
              min={5}
              max={95}
              step={5}
              updatePlayer={updatePlayer}
              value={tempPlayer.takePct}
            />
          </tr>
        </tbody>
      </table>
    </fieldset>
  );
}
