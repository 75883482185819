import { Actor, GameContext, PlayerGameState } from 'state/types';
import { ToScreenFunc } from './utils';
import { DrawOptions } from './drawRink';

export function drawDebugPlayerReach(
  player: PlayerGameState,
  context: CanvasRenderingContext2D,
  toScreen: ToScreenFunc
) {
  context.beginPath();
  context.arc(
    ...toScreen(player.x, player.y),
    player.info.reach,
    0,
    Math.PI * 2
  );
  context.fillStyle = '#3222';
  context.fill();
  context.strokeStyle = '#3eee';
  context.stroke();
}

export function getStaminaColor(stamina: number) {
  return stamina < 0.2 ? 'red' : stamina < 0.6 ? 'yellow' : 'green';
}

export function drawPlayer(
  actor: Actor,
  gameContext: GameContext,
  drawContext: CanvasRenderingContext2D,
  toScreen: ToScreenFunc,
  options: DrawOptions
) {
  // draw a player
  const player = actor as PlayerGameState;

  // 'A' is home team, 'B' is away team
  const teamColor =
    player.team === 'A' ? gameContext.teamA.color1 : gameContext.teamB.color2;

  // debug: draw the reach
  if (options.drawReach) {
    drawDebugPlayerReach(player, drawContext, toScreen);
  }

  const [px, py] = toScreen(actor.x, actor.y);

  if (player.info.faceDataURL) {
    // yuck
    const img = new Image(32, 32);
    img.src = player.info.faceDataURL || '';
    drawContext.drawImage(img, px - 16, py - 16);
  }

  if (options.drawCooldown) {
    drawDebugCooldown(player, drawContext, px, py);
  }

  // const playerRadius = 10
  // context.fillRect(px - playerRadius, py - playerRadius, 2 * playerRadius, 2 * playerRadius)
  drawContext.beginPath();
  drawContext.font = 'bold 16px Tahoma,sans-serif';
  drawContext.fillStyle = teamColor;
  const labelJersey = [player.info.jersey].join(' ');
  drawContext.fillText(labelJersey, px, py);

  drawContext.beginPath();
  drawContext.font = '10px sans-serif';
  const labelName = [player.info.name, player.focus?.cooldown].join(' ');
  drawContext.fillText(labelName, px - 16, py + 24);

  // draw stamina
  drawContext.beginPath();
  drawContext.rect(px - 15, py + 12, player.stamina * 30, 4);
  drawContext.fillStyle = getStaminaColor(player.stamina);
  drawContext.fill();

  // // debug: draw the aim
  // if (player.aimx && player.aimy) {
  //     drawContext.beginPath();
  //     drawContext.moveTo(px, py)
  //     drawContext.lineTo(...toScreen(player.x + player.aimx, player.y + player.aimy))
  //     drawContext.strokeStyle = "#331133"
  //     drawContext.stroke();
  // }

  // debug: draw the focus
  if (options.drawFocus) {
    if (player.focus && (player.focus.destx || player.focus.desty)) {
      drawContext.beginPath();
      drawContext.moveTo(px, py);
      drawContext.lineTo(
        ...toScreen(player.focus.destx || 0, player.focus.desty || 0)
      );
      drawContext.strokeStyle = '#de0';
      drawContext.stroke();
    }
  }
}

function drawDebugCooldown(
  player: PlayerGameState,
  context: CanvasRenderingContext2D,
  px: number,
  py: number
) {
  let color = '';

  if (player.focus?.cooldown !== undefined) {
    if (player.focus.cooldown > 0) {
      switch (player.focus.id) {
        case 'checked':
          color = '#F003';
          break;
        default:
          color = '#3333';
      }
    }
  } else if (player.focus?.id === 'bench') {
    color = '#22F3';
  }

  if (color !== '') {
    context.beginPath();
    context.fillStyle = color;
    context.arc(px, py, 30, 0, 2 * Math.PI);
    context.fill();
  }
}
