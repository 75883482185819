import { Actor, GameContext, GameState } from 'state/types';
import { drawPuck } from './drawPuck';
import { drawPlayer } from './drawPlayer';
import { arenaToScreen } from './utils';

export interface DrawOptions {
  drawCooldown: boolean;
  drawFocus: boolean;
  drawReach?: boolean;
}

function drawActors(
  state: GameState,
  gameContext: GameContext,
  drawContext: CanvasRenderingContext2D,
  toScreen: (x: number, y: number) => [number, number],
  options: DrawOptions
) {
  let puck: Actor | undefined;

  if (state) {
    state.actors.forEach((actor) => {
      switch (actor.type) {
        case 'puck':
          puck = actor;
          break;
        case 'player':
          drawPlayer(actor, gameContext, drawContext, toScreen, options);
      }
    });

    // always draw the puck last
    if (puck) {
      drawPuck(puck, drawContext, toScreen);
    }
  }
}

export function drawRink(
  rinkCanvas: HTMLCanvasElement,
  state: GameState,
  gameContext: GameContext,
  options: DrawOptions
) {
  const drawContext = rinkCanvas.getContext('2d');
  const arena = gameContext.arena;
  const toScreen = (x: number, y: number) =>
    arenaToScreen(x, y, arena, rinkCanvas);
  // klunky
  // const toScreenLen = (arenaDist: number) => toScreen(arenaDist, 0)[0] - toScreen(0, 0)[0];

  // const { x, y } = arenaToScreen(0, 0, arena, rinkRef.current);
  // console.log(x, y)

  if (drawContext) {
    // white ice!
    drawContext.fillStyle = 'white';
    const topLeft = toScreen(-arena.length, -arena.width);
    const bottomRight = toScreen(arena.length, arena.width);

    // context.fillRect(topLeft.x, topLeft.y, bottomRight.x, bottomRight.y);
    drawContext.fillRect(...topLeft, ...bottomRight);

    // center ice
    drawContext.beginPath();
    drawContext.strokeStyle = 'red';
    drawContext.moveTo(...toScreen(0, -arena.width));
    drawContext.lineTo(...toScreen(0, arena.width));
    drawContext.stroke();

    [1, -1].forEach((i) => {
      // blue lines
      drawContext.beginPath();
      drawContext.strokeStyle = 'blue';
      drawContext.moveTo(...toScreen(i * arena.blueLine, -arena.width));
      drawContext.lineTo(...toScreen(i * arena.blueLine, arena.width));
      drawContext.stroke();

      // bench
      drawContext.beginPath();
      drawContext.fillStyle = 'yellow';
      drawContext.strokeStyle = 'orange';
      drawContext.moveTo(...toScreen(i * arena.blueLine, -arena.width));
      drawContext.lineTo(...toScreen(i * arena.blueLine, 10 - arena.width));
      drawContext.lineTo(
        ...toScreen(i * (arena.blueLine - arena.benchWidth), 10 - arena.width)
      );
      drawContext.lineTo(
        ...toScreen(i * (arena.blueLine - arena.benchWidth), 10 - arena.width)
      );
      drawContext.fill();
      drawContext.stroke();

      // crease
      drawContext.beginPath();
      drawContext.fillStyle = 'SteelBlue';
      // radius needs to be in screen dimensions
      drawContext.moveTo(...toScreen(i * arena.goalLine, 40));
      drawContext.lineTo(...toScreen(i * (arena.goalLine - 45), 40));
      drawContext.lineTo(...toScreen(i * (arena.goalLine - 45), -40));
      drawContext.lineTo(...toScreen(i * arena.goalLine, -40));
      // const radius = toScreenLen(60);
      // drawContext.arc(
      //   ...toScreen(i * arena.goalLine, 0),
      //   radius,
      //   -Math.PI / 2,
      //   Math.PI / 2,
      //   i > 0 // counterclockwise for the other side
      // )
      drawContext.fill();

      drawContext.strokeStyle = 'red';
      drawContext.stroke();

      // goal - 72 inches by 40 inches ~ 6 feet by 3.3 feet
      drawContext.beginPath();
      drawContext.strokeStyle = 'black';
      drawContext.fillStyle = 'lightgrey';
      drawContext.moveTo(...toScreen(i * arena.goalLine, 30));
      drawContext.lineTo(...toScreen(i * (arena.goalLine + 33), 30));
      drawContext.lineTo(...toScreen(i * (arena.goalLine + 33), -30));
      drawContext.lineTo(...toScreen(i * arena.goalLine, -30));
      drawContext.stroke();
      drawContext.fill();

      // goal lines
      drawContext.beginPath();
      drawContext.strokeStyle = 'red';
      drawContext.moveTo(...toScreen(i * arena.goalLine, -arena.width));
      drawContext.lineTo(...toScreen(i * arena.goalLine, arena.width));
      drawContext.stroke();
    });

    drawActors(state, gameContext, drawContext, toScreen, options);
  }
}
