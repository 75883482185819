import { TeamInfo } from './entities/TeamInfo';
import { makeInitialState } from './makeInitialState';
import { tick } from './tick';
import { Arena, GameContext, RandFunc } from './types';

export async function runGame(
  teamA: TeamInfo,
  teamB: TeamInfo,
  rand: RandFunc
) {
  const context = new GameContext();
  context.arena = new Arena();
  context.teamA = teamA;
  context.teamB = teamB;

  // initial state
  let currentState = await makeInitialState(context, Math.random);

  context.states = [currentState];

  do {
    const nextState = tick(currentState, context, rand);
    context.states.push(nextState);
    currentState = nextState;
  } while (currentState.time < 3600);

  return context;
}
