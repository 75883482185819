import { AllTeams } from 'state/team';
import { Button } from './button';
import { useEffect, useMemo, useState } from 'react';
import { GameContext, RandFunc } from 'state/types';
import { GameWorkerMessage } from 'workers/workerTypes';

export interface FullSeasonComponentProps {
  allTeams: AllTeams;
  rand: RandFunc;
}

export function FullSeasonComponent(props: FullSeasonComponentProps) {
  const { allTeams } = props;

  const [seasonId, setSeasonId] = useState<string>('' + Date.now().valueOf());
  const [summaries, setSummaries] = useState<string[]>([]);

  const gameWorker: Worker = useMemo(
    () => new Worker(new URL('../workers/gameWorker.ts', import.meta.url)),
    []
  );

  useEffect(() => {
    gameWorker.onmessage = (e) => {
      const ns = [...summaries, e.data];
      console.log(ns);
      setSummaries(ns);
    };
  }, [gameWorker, summaries]);

  async function go() {
    const keys = Object.keys(allTeams);

    for (let i = 0; i < keys.length; ++i) {
      for (let j = i + 1; j < keys.length; ++j) {
        const ctx = new GameContext();
        ctx.teamA = allTeams[keys[i]];
        ctx.teamB = allTeams[keys[j]];
        // runGame(teamA, teamB, rand);

        const msg: GameWorkerMessage = {
          seasonId: seasonId,
          gameId: ['G', i, j].join('_'),
          game: ctx,
        };
        gameWorker.postMessage(msg);
      }
    }
  }

  return (
    <div>
      <div>Full Season</div>
      <div>
        <label htmlFor="seasonId">Season Id:</label>
        <input
          id="seasonId"
          type="text"
          value={seasonId}
          onChange={(e) => {
            setSeasonId(e.target.value);
          }}
        />
        <Button onClick={go}>Go</Button>
      </div>
      <div>
        {summaries.map((s) => (
          <div>{s}</div>
        ))}
      </div>
    </div>
  );
}
