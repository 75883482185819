import { useCallback, useEffect, useState } from 'react';
import './App.css';
import { Button } from './components/button';
import { AllTeams, loadAllTeams } from 'state/team';
import { TeamEditor } from 'components/TeamEditor';
import { SingleGameComponent } from 'components/SingleGameComponent';
import { FullSeasonComponent } from 'components/FullSeasonComponent';
import { RandFunc } from 'state/types';

type AppMode = 'teamEditor' | 'singleGame' | 'fullSeason';

function App() {
  const [appMode, setAppMode] = useState<AppMode>('singleGame');

  const [allTeams, setAllTeams] = useState<AllTeams>({});

  const rand = useCallback<RandFunc>(() => Math.random(), []);

  const reloadTeams = useCallback(async () => {
    const allTeams = await loadAllTeams(window.localStorage, rand);
    setAllTeams(allTeams);
    return allTeams;
  }, [rand]);

  useEffect(() => {
    // shenanigans
    async function doIt() {
      await reloadTeams();
    }
    doIt();
  }, [reloadTeams]);

  return (
    <div className="App">
      {/* <header className="App-header"></header> */}
      <div>
        <Button onClick={() => setAppMode('singleGame')}>Single Game</Button>
        <Button onClick={() => setAppMode('fullSeason')}>Full Season</Button>
        <Button onClick={() => setAppMode('teamEditor')}>Edit Teams</Button>
      </div>

      {appMode === 'singleGame' && (
        <SingleGameComponent allTeams={allTeams} rand={rand} />
      )}

      {appMode === 'fullSeason' && (
        <FullSeasonComponent allTeams={allTeams} rand={rand} />
      )}

      {appMode === 'teamEditor' && (
        <TeamEditor
          allTeams={allTeams}
          onSave={reloadTeams}
          onDelete={reloadTeams}
          storage={localStorage}
          rand={rand}
        />
      )}
    </div>
  );
}

export default App;
