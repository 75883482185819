import { dist, setPlayersForFaceoff, tickPlayer } from './player';
import { tickPuck } from './puck';
import {
  Actor,
  ActorLookup,
  GameContext,
  GameMode,
  GameState,
  Puck,
  RandFunc,
  TickParams,
} from './types';

export function tick(
  state: GameState,
  context: GameContext,
  rand: RandFunc
): GameState {
  const newState = { ...state };
  newState.actors = state.actors.map((a) => {
    return {
      ...a,
      focus: { ...a.focus },
    };
  });

  newState.time = state.time + 1;
  newState.mode = GameMode.play;

  // set up any special conditions...
  tickFaceOff(newState, context, rand);

  // measure stuff
  const distLookup = computeDistLookup(newState.actors);

  newState.actors
    // .sort((a, b) => a.sort - b.sort)
    .forEach((actor) => {
      const tickp: TickParams = {
        actor: actor,
        newState: newState,
        context: context,
        rand: rand,
        distLookup,
      };
      switch (actor.type) {
        case 'puck':
          tickPuck(tickp);
          break;
        case 'player':
          tickPlayer(tickp);
          break;
      }
    });

  newState.message = [
    ...newState.actors.filter((a) => a.message).map((a) => a.message),
  ].join('; ');

  return newState;
}

function tickFaceOff(state: GameState, context: GameContext, rand: RandFunc) {
  // check if we should start a new faceoff
  const thePuck = state.actors.find((a) => a.type === 'puck') as Puck;
  const newPeriod = state.time % 1200 === 0;

  if (thePuck?.goal || newPeriod) {
    state.mode = GameMode.faceoff;

    // reset the puck
    delete thePuck.focus;
    thePuck.x = thePuck.y = thePuck.vx = thePuck.vy = 0;
    thePuck.goal = false;

    // move all the players for faceoffs
    setPlayersForFaceoff('A', state, context, rand);
    setPlayersForFaceoff('B', state, context, rand);
  }
}

function isActive(actor: Actor) {
  return actor.focus?.id !== 'onbench';
}

function computeDistLookup(actors: Actor[]) {
  const lookup: ActorLookup[] = [];
  for (let i = 0; i < actors.length; ++i) {
    if (isActive(actors[i])) {
      for (let j = 0; j < i; ++j) {
        if (isActive(actors[j]))
          lookup.push({
            actor1: actors[i],
            actor2: actors[j],
            dist: dist(actors[i].x - actors[j].x, actors[i].y - actors[j].y),
          });
      }
    }
  }
  return lookup.sort((a, b) => a.dist - b.dist);
}
