import { getStaminaColor } from 'draw/drawPlayer';
import { TeamInfo } from 'state/entities/TeamInfo';
import { GameState, PlayerGameState, TeamId } from 'state/types';

export interface TeamInfoPanelProps {
  state: GameState | null;
  team: TeamId;
  teamInfo: TeamInfo;
}

function formatTOI(toi: number) {
  const min = String(Math.floor(toi / 60));
  const sec = String(toi % 60).padStart(2, '0');
  return min + ':' + sec;
}

export function TeamInfoPanel(props: TeamInfoPanelProps) {
  const { team, state, teamInfo } = props;

  return (
    <div className="teamInfo">
      <div>{teamInfo?.teamName}</div>
      <div>{team === 'A' ? state?.teamAScore : state?.teamBScore}</div>
      <table className="teamInfoTable">
        <thead>
          <tr>
            <td width={'20%'}>Player</td>
            <td width={'5%'}>S</td>
            <td width={'5%'}>G</td>
            <td width={'5%'}>TOI</td>
            <td width={'5%'}>Stam.</td>
            <td>Action</td>
          </tr>
        </thead>
        <tbody>
          {state?.actors
            .filter((a) => (a as PlayerGameState).team === team)
            .map((p) => (
              <PlayerInfoPanel key={p.id} player={p as PlayerGameState} />
            ))}
        </tbody>
      </table>
    </div>
  );
}

interface PlayerInfoPanelProps {
  player: PlayerGameState;
}

function PlayerInfoPanel(props: PlayerInfoPanelProps) {
  const { player } = props;

  return (
    <tr>
      <td>
        <img
          src={player.info.faceDataURL}
          height="24"
          width="24"
          alt={player.info.name}
        />
        {player.info.name} (#{player.info.jersey})
      </td>
      <td>{player.gameStats.shots}</td>
      <td>{player.gameStats.goals}</td>
      <td>{formatTOI(player.gameStats.toi)}</td>
      <td>
        <span style={{ color: getStaminaColor(player.stamina) }}>
          {player.stamina.toFixed(3)}
        </span>
      </td>
      <td>{player.message}</td>
    </tr>
  );
}
