import { createPuck } from './puck';
import { createPlayerGameState, setPlayersForFaceoff } from './player';
import {
  Actor,
  Arena,
  GameContext,
  GameState,
  RandFunc,
  TeamId,
} from './types';
import { TeamInfo } from './entities/TeamInfo';

async function makeTeam(
  team: TeamId,
  teamInfo: TeamInfo,
  arena: Arena,
  rand: RandFunc
): Promise<Actor[]> {
  const players = await Promise.all(
    teamInfo.players.map((playerInfo) => {
      const id = [team, playerInfo.position, playerInfo.line].join('_');
      return createPlayerGameState(team, id, playerInfo, arena, rand);
    })
  );

  return players;
}

export async function makeInitialState(context: GameContext, rand: RandFunc) {
  const initialState = new GameState();
  initialState.message = "It's hockey time!";

  // set up the arena, players, etc...
  initialState.actors.push(createPuck(rand));
  initialState.actors.push(
    ...(await makeTeam('A', context.teamA, context.arena, rand))
  );
  initialState.actors.push(
    ...(await makeTeam('B', context.teamB, context.arena, rand))
  );

  setPlayersForFaceoff('A', initialState, context, rand);
  setPlayersForFaceoff('B', initialState, context, rand);

  return initialState;
}
