import { AllTeams, deleteTeam, saveTeam } from 'state/team';
import { TeamInfo } from 'state/entities/TeamInfo';
import { TeamPicker } from './TeamPicker';
import { useEffect, useState } from 'react';
import { Button } from './button';
import { PlayerInfo } from 'state/entities/PlayerInfo';
import { PlayerEditor } from './PlayerEditor';
import { makeRandomPlayer } from 'state/player';
import { RandFunc } from 'state/types';

export interface TeamEditorProps {
  allTeams: AllTeams;
  storage: Storage;
  rand: RandFunc;
  onSave: () => void;
  onDelete: () => void;
}

export function TeamEditor(props: TeamEditorProps) {
  const { allTeams, storage, rand, onSave, onDelete } = props;

  const [currID, setCurrID] = useState('');
  const [autoSave, setAutoSave] = useState(true);
  const [tempTeam, setTempTeam] = useState<TeamInfo>();

  useEffect(() => {
    setTempTeam(allTeams[currID]);
  }, [allTeams, currID]);

  function localSaveTeam() {
    if (tempTeam) {
      doSave(tempTeam);
    }
  }

  function doSave(info: TeamInfo) {
    saveTeam(info, storage);
    onSave();
  }

  function localDeleteTeam() {
    if (tempTeam) {
      deleteTeam(tempTeam, storage);
      onDelete();
    }
  }

  function updateTempTeam(info: Partial<TeamInfo>) {
    if (tempTeam) {
      const updatedTeam = { ...tempTeam, ...info };
      setTempTeam(updatedTeam);
      if (autoSave) {
        doSave(updatedTeam);
      }
    }
  }

  async function localAddPlayer() {
    if (tempTeam) {
      // should probably be somewhere else...
      const newPlayer = await makeRandomPlayer(rand, 'C', 1);
      updateTempTeam({
        players: [...tempTeam.players, newPlayer],
      });
    }
  }

  function onPlayerChanged(newInfo: PlayerInfo, oldInfo: PlayerInfo | null) {
    if (tempTeam) {
      const updatedPlayers = tempTeam.players.map((p) =>
        p.id === oldInfo?.id ? newInfo : p
      );
      updateTempTeam({
        players: updatedPlayers,
      });
    }
  }

  function onPlayerDelete(playerToDelete: PlayerInfo) {
    if (tempTeam) {
      const updatedPlayers = tempTeam.players.filter(
        (p) => p.id !== playerToDelete.id
      );
      updateTempTeam({
        players: updatedPlayers,
      });
    }
  }

  return (
    <div>
      <div>
        <TeamPicker
          allTeams={allTeams}
          id="edit"
          selectedTeam={currID}
          onSelectionChanged={setCurrID}
        />
      </div>
      <div>
        <form>
          <input
            id="editTeamID"
            title="Team ID"
            type="text"
            value={tempTeam?.teamID}
            onChange={(e) => {
              if (tempTeam) {
                updateTempTeam({ teamID: e.target.value });
              }
            }}
          />
          <input
            id="editTeamName"
            title="Team Name"
            type="text"
            value={tempTeam?.teamName}
            onChange={(e) => {
              if (tempTeam) {
                updateTempTeam({ teamName: e.target.value });
              }
            }}
          />
          <label htmlFor="editColor1">Home Colour</label>
          <input
            id="editColor1"
            title="Home Colour"
            type="color"
            value={tempTeam?.color1}
            onChange={(e) => {
              if (tempTeam) {
                updateTempTeam({ color1: e.target.value });
              }
            }}
          />
          <label htmlFor="editColor2">Away Colour</label>
          <input
            id="editColor2"
            title="Away Colour"
            type="color"
            value={tempTeam?.color2}
            onChange={(e) => {
              if (tempTeam) {
                updateTempTeam({ color2: e.target.value });
              }
            }}
          />
          <Button onClick={localSaveTeam}>Save team</Button>
          <input
            type="checkbox"
            title="Auto Save"
            checked={autoSave}
            onChange={(e) => setAutoSave(e.target.checked)}
          />
          <Button onClick={localDeleteTeam}>Delete team</Button>

          {tempTeam?.players && (
            <div>
              {tempTeam.players.map((player) => (
                <PlayerEditor
                  player={player}
                  key={'key' + player.id}
                  onPlayerChanged={onPlayerChanged}
                  onDelete={onPlayerDelete}
                />
              ))}
            </div>
          )}
        </form>
      </div>
      <Button onClick={localAddPlayer}>New Player</Button>
    </div>
  );
}
